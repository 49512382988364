import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ApiResp } from '@app/types/global.type';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductGuideService extends BaseService {
  getUserManual() {
    return this.httpClient.get<ApiResp>(`/myportal/user-manual`).pipe(
      map((resp) => {
        if (resp.status) {
          const pdfUrl = resp.response;
          window.open(pdfUrl, '_blank');
        } else this.toastr.error(resp.message, 'Error');
      }),
    );
  }

  getReleaseNotes() {
    return this.httpClient
      .get<ApiResp>(`/admin/release-notes/get`, {
        headers: { 'admin': 'true', 'Content-Type': 'application/json' },
      })
      .pipe(
        map((resp) => {
          if (!resp.status) this.toastr.error(resp.message);
          return resp;
        }),
      );
  }
}
