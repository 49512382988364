<div class="forget-password-container">
  <div class="forget-password-box">
    <div class="mb-4">
      <img [src]="store.brandMeta()?.logo" [alt]="store.brandMeta()?.shortName" width="70" />
    </div>

    <div>
      <form [formGroup]="forgotPasswordForm" (submit)="resetPassword()">
        <h1 class="tw-text-lg tw-font-semibold mb-1">Trouble Logging In?</h1>
        <p class="help-text">
          Enter email to reset password for your account. We will use the OTP to verify the action and set the new
          password for you.
        </p>
        <div class="tw-flex-1">
          <div class="form-group mt-4">
            <label>Email Address</label>
            <input
              required
              type="email"
              class="form-control"
              placeholder="name@example.com"
              formControlName="userId"
              required
            />
            <div class="input-error">
              @if (forgotPasswordForm.controls['userId'].errors) {
                <p>Email Address is invalid or empty</p>
              }
            </div>
          </div>
          <div class="form-group mt-2">
            @if (!showTimer) {
              <button class="text-link btn-xs p-0 text-primary" (click)="getOTP($event)">Get OTP</button>
            } @else {
              <label class="timer">Resend OTP in {{ otpExpirationTime }}s</label>
            }
          </div>
          <div class="form-group mt-4">
            <label class="tw-mt-4">OTP (One Time Password)</label>
            <input type="text" formControlName="otp" class="form-control" placeholder="OTP" required />

            <div class="input-error">
              @if (forgotPasswordForm.controls['otp'].errors) {
                <p>OTP is invalid or empty</p>
              }
            </div>
          </div>
          <div class="form-group mt-4">
            <label>New Password</label>
            <div class="input-prefix password-container">
              <input
                [type]="passwordFieldType"
                formControlName="newPassword"
                class="form-control"
                placeholder="Password"
                required
              />
              <button type="button" class="password-toggle" (click)="togglePasswordVisibility()">
                @if (passwordFieldType === 'password') {
                  <ng-icon name="matVisibilityOff" size="16" />
                } @else {
                  <ng-icon name="matRemoveRedEye" size="16" />
                }
              </button>
            </div>
            @if (
              forgotPasswordForm.controls['newPassword'].dirty && forgotPasswordForm.controls['newPassword'].errors
            ) {
              <p class="error">
                Passwords must contain minimum eight and maximum sixteen characters, including uppercase, lowercase
                letters, numbers, and special characters (&#64;#$%^&+=.[]*?!_)
              </p>
            }
          </div>
          <div class="form-group mt-4">
            <label>Confirm New Password</label>
            <input
              type="password"
              formControlName="confirmNewPassword"
              class="form-control"
              placeholder="New Password"
              required
            />

            <div class="input-error">
              @if (forgotPasswordForm.errors?.['passwordMismatch']) {
                <p>Passwords do not match.</p>
              }
            </div>
          </div>
          <div class="form-group mt-4">
            <button [disabled]="forgotPasswordForm.invalid" class="btn btn-primary full">Reset Password</button>

            <div class="mt-3 text-center">
              <a class="btn-link btn-sm" routerLink="/login">&#8592; Back to Login </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
