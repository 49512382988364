import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@app/config/store';
import { DtoRegex, email, noSpacesValidator, otp } from '@app/dto/common.dto';
import { AuthService } from '@app/services/auth.service';
import { RecaptchaService } from '@app/services/recaptcha.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matRemoveRedEye, matVisibilityOff } from '@ng-icons/material-icons/baseline';
import { matDeleteOutlineOutline } from '@ng-icons/material-icons/outline';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, NgIconComponent, CommonModule],
  providers: [provideIcons({ matDeleteOutlineOutline, matRemoveRedEye, matVisibilityOff })],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  showTimer: boolean = false;
  otpExpirationTime: number = 30;
  isMfaUserTemporaryDisable: boolean = false;

  redirectURL: string = '';
  passwordFieldType: string = 'password';
  isMfaRegistered: boolean = false;

  passwordExpired: boolean = false;
  loginForm: FormGroup = new FormGroup({
    userId: new FormControl('', [email]),
    password: new FormControl('', []),
    token: new FormControl('', []),
    isUserConsent: new FormControl(false),
  });

  mfaOtpValidationForm: FormGroup = new FormGroup({
    userId: new FormControl({ value: '', disabled: true }, []),
    otp: new FormControl('', [noSpacesValidator(), otp]),
  });

  constructor(
    private router: Router,
    protected store: Store,
    private authSvc: AuthService,
    private recaptcha: RecaptchaService,
    private ngbModal: NgbModal,
  ) {
    const redirectUrl = this.router.parseUrl(this.router.url).queryParams['redirectUrl'];

    if (redirectUrl && redirectUrl !== '') sessionStorage.setItem('redirectUrl', redirectUrl);

    this.redirectURL = sessionStorage.getItem('redirectUrl') ?? '';
    sessionStorage.removeItem('redirectUrl');

    this.loginForm.controls['userId'].valueChanges
      .pipe(
        tap(() => {
          if (this.loginForm.controls.isUserConsent.value) {
            this.loginForm.controls.isUserConsent.setValue(false);
          }
        }),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((val) => {
        this.handleInput(val);
      });
  }

  login(): void {
    const payload = this.loginForm.getRawValue();
    this.authSvc.login(payload).subscribe((resp) => {
      if (resp) {
        if (resp.passwordExpired) {
          this.passwordExpired = true;
          return;
        }

        this.store.setTaxpayer(resp);
        this.isMfaRegistered = resp.isMfaRegistered;
        if (resp.isMfaEnable && !resp.isDummyUser) {
          this.router.navigate(['/config-mfa'], {
            queryParams: { redirectUrl: this.redirectURL, isMfaRegistered: this.isMfaRegistered },
          });
        } else {
          const query = this.redirectURL !== '' ? { redirectUrl: this.redirectURL } : {};
          this.router.navigate(['/redirect'], { queryParams: query });
        }
      }
    });
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  verifyCaptcha() {
    this.recaptcha.execute('login').then((token) => {
      if (token) {
        this.loginForm.patchValue({ token });
        this.login();
      }
    });
  }

  forgetPassword() {
    this.router.navigate(['/forget-password'], {
      queryParams: { email: this.loginForm.value.userId },
    });
  }

  handleInput(userId: string) {
    if (!userId?.trim()) return;

    this.loginForm.controls.isUserConsent.enable();
    const emailRegex = DtoRegex.email;

    if (emailRegex.test(userId)) {
      this.authSvc.getConsentStatus(userId).subscribe((resp) => {
        if (resp && resp.response) {
          const isUserConsent = resp.response?.isUserConsent ?? false;
          this.loginForm.controls.isUserConsent.setValue(isUserConsent);
          if (isUserConsent) this.loginForm.controls.isUserConsent.disable();
        }
      });
    }
  }

  dismissModal() {
    this.ngbModal.dismissAll();
  }
}
