import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  WritableSignal,
  signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@app/config/store';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { GlobalSearchResult } from '@app/types/global.type';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matSellOutline,
  matArrowCircleLeftOutline,
  matWidthFullOutline,
  matDashboardOutline,
  matSettingsOutline,
  matArticleOutline,
  matPictureAsPdfOutline,
  matDomainOutline,
  matLoyaltyOutline,
  matNotificationsOutline,
  matDocumentScannerOutline,
  matPrintOutline,
  matGroupOutline,
  matBusinessOutline,
  matHandshakeOutline,
  matReceiptLongOutline,
  matTransformOutline,
  matMailOutline,
  matBuildOutline,
  matHubOutline,
  matAccountTreeOutline,
  matUploadFileOutline,
  matInventoryOutline,
  matListAltOutline,
  matMenuBookOutline,
} from '@ng-icons/material-icons/outline';
import {
  matConstruction,
  matWarningAmber,
  matStackedLineChart,
  matShare,
  matPersonAdd,
  matOpenInNew,
  matNotificationsActive,
  matSchedule,
} from '@ng-icons/material-icons/baseline';

type MenuItemWithAccess = Menu & { access: boolean };

export type Menu = {
  title: string;
  icon: string;
  collapse?: boolean;
  class?: string;
  menu?: MenuItemWithAccess[];
  route?: string;
  click?: () => void;
};

@Component({
  selector: 'app-sidenav, [app-sidenav]',
  standalone: true,
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.css',
  imports: [RouterModule, ReactiveFormsModule, NgIconComponent],
  viewProviders: [
    provideIcons({
      matOpenInNew,
      matHandshakeOutline,
      matBusinessOutline,
      matGroupOutline,
      matHubOutline,
      matNotificationsOutline,
      matArrowCircleLeftOutline,
      matWidthFullOutline,
      matDashboardOutline,
      matConstruction,
      matSettingsOutline,
      matWarningAmber,
      matStackedLineChart,
      matShare,
      matArticleOutline,
      matPictureAsPdfOutline,
      matSellOutline,
      matDomainOutline,
      matUploadFileOutline,
      matLoyaltyOutline,
      matPrintOutline,
      matDocumentScannerOutline,
      matReceiptLongOutline,
      matTransformOutline,
      matMailOutline,
      matPersonAdd,
      matBuildOutline,
      matAccountTreeOutline,
      matInventoryOutline,
      matNotificationsActive,
      matSchedule,
      matListAltOutline,
      matMenuBookOutline,
    }),
  ],
})
export class SidenavComponent implements OnInit {
  @Input() app!: { name: string; icon: string };
  @Input() menu!: Menu[];
  @Input() search: boolean = false;
  @Input() searchResults: GlobalSearchResult[] = [];
  @Output() resultEvent = new EventEmitter<string>();
  @Output() searchAction = new EventEmitter<GlobalSearchResult>();

  minimized: WritableSignal<boolean> = signal(false);
  searchText = new FormControl('');

  searchModalVisible: boolean = false;

  constructor(protected store: Store) {}

  ngOnInit(): void {
    this.menu.forEach((group) => {
      const visible = group.menu?.some((item) => item.access);
      if (!visible) group.menu = undefined;
    });
  }

  toggleMenu() {
    this.store.miniSidenav.update((val) => !val);
    localStorage.setItem('miniSidebar', this.store.miniSidenav().toString());
  }

  toggleGroupMenu(index: number) {
    this.menu[index].collapse = !this.menu[index].collapse;
    this.menu = [...this.menu]; // force rerendering of the component
  }

  openSearch() {
    this.searchModalVisible = true;
  }

  handleSearch() {
    this.resultEvent.emit(this.searchText.value ?? '');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResultAction(item: any) {
    this.searchAction.emit(item);

    this.searchModalVisible = false;
    this.searchResults = [];
    this.searchText.patchValue('');
  }
}
