import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@app/config/store';
import { password, passwordMatchValidator } from '@app/dto/common.dto';
import { SetPassword, SetPasswordService } from '@app/services/set-password.service';
import { TinsService } from '@app/services/tins.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matRemoveRedEye, matVisibilityOff } from '@ng-icons/material-icons/baseline';

@Component({
  selector: 'app-set-password',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, CommonModule, NgIconComponent],
  providers: [
    provideIcons({
      matRemoveRedEye,
      matVisibilityOff,
    }),
  ],
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.css',
})
export class SetPasswordComponent {
  passwordFieldType: string = 'password';
  passwordForm: FormGroup = new FormGroup(
    {
      email: new FormControl({ value: '', disabled: true }),
      password: new FormControl('', [Validators.required, password]),
      confirmPassword: new FormControl('', [Validators.required, password]),
      token: new FormControl('', []),
    },
    { validators: passwordMatchValidator() },
  );

  constructor(
    protected store: Store,
    private router: Router,
    private setPasswordSvc: SetPasswordService,
    private tinService: TinsService,
  ) {
    const token = this.router.parseUrl(this.router.url).queryParams['token'];

    if (!token || token === '' || token?.split('.').length !== 3) {
      this.router.navigate(['/login']);
      return;
    }

    const payload = JSON.parse(atob(token.split('.')[1]));
    this.passwordForm.controls['email'].setValue(payload.ue);
    this.passwordForm.controls['token'].setValue(token);
  }

  setPassword(): void {
    const payload: SetPassword = this.passwordForm.value;
    this.setPasswordSvc.setPassword(payload).subscribe((resp) => {
      if (resp) {
        this.store.setTaxpayer(resp);

        this.router.navigate(['/redirect']);
      }
    });
  }
  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
}
