<div class="app-body tw-relative">
  <app-header class="header" />
  <div class="tw-flex tw-relative">
    @if (!store.sideNavHide()) {
      <app-sidenav [app]="{ name: 'E-Invoice', icon: 'matReceiptLongOutline' }" [menu]="sidenavMenu" [search]="false" />
    }
    <div
      class="tw-flex-1 tw-min-w-0 page-wrapper"
      [class]="{
        'tw-bg-white': hasWhiteBg(),
      }"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #createEinvoice>
  <app-einvoice-create />
</ng-template>
