import { Routes } from '@angular/router';
import { LoginComponent } from '@app/pages/auth/login/login.component';
import { TaxpayerComponent } from '@app/pages/taxpayer/taxpayer.component';
import { GarnetComponent } from '@app/pages/garnet/garnet.component';
import { SetPasswordComponent } from '@app/pages/auth/set-password/set-password.component';
import { AccountComponent } from '@app/pages/account/account.component';
import { publicAuth, userAuth } from '@app/mware/guards/auth.guard';
import { RedirectComponent } from '@app/pages/auth/redirect/redirect.component';
import { ForgetPasswordComponent } from '@app/pages/auth/forget-password/forget-password.component';
import { Error403Component } from '@app/pages/errors/error-403/error-403.component';
import { MasterCustomerCreateComponent } from '@app/pages/account/master-customer-create/master-customer-create.component';
import { VerifyVendorComponent } from '@app/pages/account/master-customer-create/verify-vendor/verify-vendor.component';
import { ConfigMfaComponent } from '@app/pages/auth/config-mfa/config-mfa.component';

export const AppRoutes: Routes = [
  { path: '', pathMatch: 'full', component: RedirectComponent },
  {
    path: 'redirect',
    title: 'Redirecting...',
    component: RedirectComponent,
    canActivate: [userAuth()],
  },
  {
    path: 'update-vendor',
    title: 'Update Vendor',
    component: MasterCustomerCreateComponent,
  },
  {
    path: 'login',
    title: 'Login | Myeinvois',
    component: LoginComponent,
    canActivate: [publicAuth()],
  },
  {
    path: 'forget-password',
    title: 'Forgot Password | Myeinvois',
    component: ForgetPasswordComponent,
    canActivate: [publicAuth()],
  },
  {
    path: 'set-password',
    title: 'Get Started | Myeinvois',
    component: SetPasswordComponent,
    canActivate: [publicAuth()],
  },
  {
    path: 'verify-vendor',
    title: 'Verify Vendor | Myeinvois',
    component: VerifyVendorComponent,
    // canActivate: [publicAuth()],
  },
  {
    path: 'config-mfa',
    title: 'Config Mfa | Myeinvois',
    component: ConfigMfaComponent,
  },
  {
    path: 'account',
    component: AccountComponent,
    title: 'Settings',
    loadChildren: () => import('./account.route').then((m) => m.AccountRoutes),
    canActivate: [userAuth()],
  },
  {
    path: 'garnet',
    title: 'Transform',
    component: GarnetComponent,
    loadChildren: () => import('./garnet.route').then((m) => m.GarnetRoutes),
    canActivate: [userAuth()],
  },
  {
    path: 'taxpayer',
    title: 'E-Invoice',
    component: TaxpayerComponent,
    loadChildren: () => import('./taxpayer.route').then((m) => m.TaxpayerRoutes),
    canActivate: [userAuth()],
  },
  // {
  //   path: 'partner',
  //   component: PartnerComponent,
  //   loadChildren: () => import('./partner.route').then((m) => m.PartnerRoutes),
  // },
  {
    path: 'base',
    loadChildren: () => import('./base').then((m) => m.BaseRoutes),
    canActivate: [userAuth()],
  },
  {
    path: 'not-authorised',
    title: 'Not Authorised',
    component: Error403Component,
    canActivate: [userAuth()],
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];
