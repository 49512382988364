<div class="otp-verification-container">
  <div class="otp-verification-box">
    <div class="mb-4">
      <img [src]="store.brandMeta()?.logo" [alt]="store.brandMeta()?.shortName" width="70" />
    </div>

    <div>
      <form>
        <h1 class="tw-text-lg tw-font-semibold mb-1">Verify Email Address</h1>
        <p class="help-text">
          The link you are trying to access has expired. To regain access, please verify your email address.
        </p>
        <div class="tw-flex-1">
          <div class="form-group mt-4">
            <label>Email Address</label>
            <input type="email" class="form-control disabled" [value]="params.email" disabled />
          </div>
          <div class="form-group tw-flex tw-items-center mt-2">
            @if (!showTimer) {
              <button
                type="button"
                class="text-link btn-xs p-0 text-primary tw-text-xs tw-ml-1"
                (click)="getOTP($event)"
              >
                Get OTP
              </button>
            } @else {
              <label class="timer">Resend OTP in {{ otpExpirationTime }}s</label>
            }
          </div>
          <div class="form-group mt-4">
            <label class="tw-mt-4">OTP (One Time Password)</label>
            <input type="text" class="form-control" [formControl]="otp" placeholder="OTP" required />

            <div class="input-error">
              @if (otp.errors) {
                <p>OTP is invalid or empty</p>
              }
            </div>
          </div>

          <div class="form-group mt-4">
            <button [disabled]="otp.invalid" class="btn btn-primary full" type="button" (click)="verifyOtp()">
              Verify OTP
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
