<form class="tw-p-8" [formGroup]="passwordForm" (submit)="savePassword()">
  <h1 class="tw-text-xl tw-font-semibold mb-1">Change Password</h1>
  <p class="help-text">Enter current password to set new one.</p>
  <div class="tw-flex-1">
    <div class="form-group mt-3">
      <label>Old Password</label>
      <input required type="password" class="form-control" formControlName="currentPassword" required />
      <div class="input-error">
        @if (passwordForm.controls['currentPassword'].errors) {
          <p>Old password cannot be blank</p>
        }
      </div>
    </div>

    <div class="form-group mt-3">
      <label>New Password</label>
      <div class="input-prefix password-container">
        <input
          [type]="viewPassword ? 'text' : 'password'"
          formControlName="newPassword"
          class="form-control"
          placeholder="Password"
          required
        />
        <button type="button" class="password-toggle" (click)="viewPassword = !viewPassword">
          @if (viewPassword) {
            <ng-icon name="matRemoveRedEye" size="16" />
          } @else {
            <ng-icon name="matVisibilityOff" size="16" />
          }
        </button>
      </div>

      <div class="input-error">
        @if (passwordForm.controls['newPassword'].errors) {
          <p>
            Passwords must contain minimum eight and maximum sixteen characters, including uppercase, lowercase letters,
            numbers, and special characters (&#64;#$%^&+=.[]*?!_)
          </p>
        }
      </div>
    </div>

    <div class="form-group mt-3">
      <label>Confirm New Password</label>
      <input
        type="password"
        class="form-control"
        placeholder="New Password"
        formControlName="confirmNewPassword"
        [class.ng-invalid]="passwordForm.errors?.passwordMismatch"
        required
      />

      <div class="input-error">
        @if (passwordForm.errors?.passwordMismatch) {
          <p>Passwords do not match.</p>
        }
      </div>
    </div>

    <div class="flex-apart mt-3">
      <button class="btn btn-light tw-px-8 tw-py-2" (click)="cancel($event)">Cancel</button>
      <button class="btn btn-primary tw-py-2 full" [disabled]="passwordForm.invalid">Change Password</button>
    </div>
  </div>
</form>
