@if (isMfaRegistered) {
  <div
    [class]="{
      'mfa-container': !isFromUpdateMfa,
    }"
  >
    <div class="mfa-setup-box">
      <div class="tw-flex tw-justify-between tw-items-start">
        <div class="mb-4">
          <img [src]="store.brandMeta()?.logo" [alt]="store.brandMeta()?.shortName" width="70" />
        </div>
        @if (isFromUpdateMfa) {
          <div>
            <button class="btn-close tw-opacity-100" (click)="dismissModal()"></button>
          </div>
        }
      </div>

      <div>
        <form [formGroup]="mfaOtpValidationForm" (submit)="verifyMfaOtp($event)">
          <h1 class="tw-text-lg tw-font-semibold mb-1">OTP Verification</h1>
          <p class="help-text">
            @if (authType === 'EMAIL') {
              Please enter the OTP (one-time-password) sent to your registered email ID to complete your verification.
            } @else {
              Please enter the OTP (one-time-password) generated by your authenticator app here.
            }
          </p>
          <div class="tw-flex-1">
            @if (authType === 'EMAIL') {
              <div class="form-group mt-4">
                <label>Email Address</label>
                <input type="email" class="form-control disabled" formControlName="userId" />
              </div>
              <div class="form-group tw-flex tw-items-center mt-2">
                @if (!showTimer) {
                  <label class="timer tw-mb-0 tw-text-xs">Didn't get the OTP?</label>
                  <button
                    type="button"
                    class="text-link btn-xs p-0 text-primary tw-text-xs tw-ml-1"
                    (click)="resendOtp()"
                  >
                    Resend OTP
                  </button>
                } @else {
                  <label class="timer">Resend OTP in {{ otpExpirationTime }}s</label>
                }
              </div>
            }

            <div class="form-group mt-4">
              <label class="tw-mt-4">OTP (One Time Password)</label>
              <input type="text" class="form-control" formControlName="otp" placeholder="OTP" required />

              <div class="input-error">
                @if (mfaOtpValidationForm.controls['otp'].errors) {
                  <p>OTP is invalid or empty</p>
                }
              </div>
            </div>

            <div class="form-group mt-4">
              <button [disabled]="mfaOtpValidationForm.invalid" class="btn btn-primary full">Verify OTP</button>
              <div class="mt-3 text-center">
                @if (authType === 'EMAIL') {
                  <button type="button" class="btn-link btn-sm" (click)="switchToAppAuthenticator()">
                    Switch to App Authenticator verification
                  </button>
                } @else {
                  <button type="button" class="btn-link btn-sm" (click)="switchToEmailOtpVerification()">
                    Switch to Email OTP verification
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
} @else {
  <div
    [class]="{
      'mfa-container': !isFromUpdateMfa,
    }"
  >
    <div class="mfa-setup-box">
      <div class="tw-flex tw-justify-between tw-items-start">
        <div class="mb-4">
          <img [src]="store.brandMeta()?.logo" [alt]="store.brandMeta()?.shortName" width="70" />
        </div>
        @if (isFromUpdateMfa) {
          <div>
            <button class="btn-close tw-opacity-100" (click)="dismissModal()"></button>
          </div>
        }
      </div>
      <h1 class="tw-text-2xl tw-font-semibold mb-1">Register For MFA</h1>
      <p class="help-text">
        MFA registration is mandatory as it has been enabled for your organization by the super admin. You will not be
        able to access the portal until MFA registration is successfully completed.
      </p>

      <ul class="tw-list-decimal tw-text-[0.8rem] tw-px-4">
        <li class="tw-py-4 tw-font-semibold tw-text-lg">
          <p>Download an Authenticator Application.</p>
          <p class="tw-font-medium tw-text-sm">
            Install an authenticator app on your smart phone. Search for 'authenticator' in your Application Store.
          </p>
        </li>
        <li class="tw-pb-4 tw-font-semibold tw-text-lg">
          <p>Scan the QR Code</p>
          <p class="tw-font-medium tw-text-sm">
            Use the freshly installed authenticator application to scan the barcode below.
          </p>
          <div class="tw-flex tw-justify-evenly tw-items-center">
            <div class="qr-code-box">
              <img [src]="qrCode" alt="'Scan QR code to register for MFA'" width="125" />
            </div>
            <div class="tw-flex tw-flex-col tw-items-start tw-gap-2">
              <p>Can't scan the QR code?</p>
              <button (click)="shouldShowSetupKey = true" class="tw-font-medium tw-text-sm tw-text-blue-600">
                View the setup key?
              </button>
              @if (shouldShowSetupKey) {
                <input type="text" class="form-control setup-key" [value]="mfaSecretKey" />
              }
            </div>
          </div>
        </li>
        <li class="tw-font-semibold tw-text-lg">
          <p>Enter the authentication code</p>
          <p class="tw-font-medium tw-text-sm">
            Enter the six-digit code generated by the authenticator app on your mobile device.
          </p>
          <input type="text" class="form-control tw-my-4" [formControl]="otp" placeholder="OTP" required />
        </li>
      </ul>

      <div class="tw-w-full">
        <button
          [disabled]="otp.invalid"
          (click)="verifyMfaOtp($event)"
          class="btn btn-primary tw-mt-6 mx-auto tw-block"
        >
          Verify
        </button>
      </div>
    </div>
  </div>
}
