import dayjs from 'dayjs';

export function handleNotifications(dates: string[]) {
  const notificationsData = JSON.parse(
    localStorage.getItem('notifications') ??
      '{"count": 0, "isUnRead": false, "isPostLogin": false,"hasCountDifference":false}',
  );

  const localCount = Number(notificationsData.count);
  const apiCount = dates.length;
  const hasCountDifference = apiCount !== localCount;

  notificationsData.count = hasCountDifference ? apiCount : localCount;
  notificationsData.isUnRead = hasCountDifference;
  notificationsData.isPostLogin = true;
  notificationsData.hasCountDifference = hasCountDifference;

  localStorage.setItem('notifications', JSON.stringify(notificationsData));

  return notificationsData;
}
export function fetchMonthlyReleaseNotes(dates: string[]) {
  if (!dates || dates.length === 0 || dates.some((date) => !dayjs(date).isValid())) return [];

  const sortedDates = dates.sort((a, b) => dayjs(b).diff(dayjs(a)));
  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();

  const filterByMonth = (month: number, year: number) => {
    return sortedDates.filter((date) => {
      const d = dayjs(date);
      return d.month() === month && d.year() === year;
    });
  };

  let filteredDates = filterByMonth(currentMonth, currentYear);

  if (filteredDates.length === 0) {
    let monthToCheck = currentMonth;
    let yearToCheck = currentYear;
    while (filteredDates.length === 0 && yearToCheck > 0) {
      monthToCheck = monthToCheck === 0 ? 11 : monthToCheck - 1;
      yearToCheck = monthToCheck === 11 ? yearToCheck - 1 : yearToCheck;
      filteredDates = filterByMonth(monthToCheck, yearToCheck);
    }
  }
  return filteredDates;
}
