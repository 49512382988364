import { Injectable } from '@angular/core';
import { config } from '@app/config/app';

interface Grecaptcha {
  ready(callback: () => void): void;
  execute(siteKey: string, options: { action: string }): Promise<string>;
}

declare const grecaptcha: Grecaptcha;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private siteKey = config.captchaKey;

  constructor() {
    this.loadRecaptchaScript();
  }

  private loadRecaptchaScript() {
    if (!document.getElementById('recaptcha-script')) {
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
      script.async = true;
      document.head.appendChild(script);
    }
  }

  execute(action: string): Promise<string | boolean> {
    return new Promise((resolve) => {
      if (typeof grecaptcha !== 'undefined') {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(this.siteKey, { action })
            .then((token) => resolve(token))
            .catch((err) => {
              console.error('reCAPTCHA error: ', err);
              resolve(false);
            });
        });
      } else {
        console.error('reCAPTCHA not loaded');
        resolve(false);
      }
    });
  }
}
