import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@app/config/store';
import { noSpacesValidator, otp } from '@app/dto/common.dto';
import { AuthService } from '@app/services/auth.service';

interface QueryParams {
  uuid: string;
  vendorId: string;
  email: string;
}

@Component({
  selector: 'app-verify-vendor',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './verify-vendor.component.html',
  styleUrl: './verify-vendor.component.css',
})
export class VerifyVendorComponent implements OnInit {
  showTimer: boolean = false;
  otpExpirationTime: number = 30;
  params!: QueryParams;

  otp: FormControl = new FormControl('', [noSpacesValidator(), otp]);

  constructor(
    private router: Router,
    protected store: Store,
    private authSvc: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((param) => {
      if (param.uuid && param.vendorId && param.email) {
        this.params = param as QueryParams;
        this.authSvc.getVendorOtp(this.params.uuid).subscribe();
      }
    });
  }

  getOTP(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.authSvc.getVendorOtp(this.params.uuid).subscribe((res) => {
      if (res.status) this.startTimer();
    });
  }

  startTimer() {
    this.showTimer = true;
    const timeInterval = setInterval(() => {
      if (this.otpExpirationTime === 0) {
        clearInterval(timeInterval);
        this.showTimer = false;
        this.otpExpirationTime = 30;
      }
      this.otpExpirationTime = this.otpExpirationTime - 1;
    }, 1000);
  }

  verifyOtp() {
    if (this.otp.invalid) return;
    const payload = {
      uuid: this.params.uuid,
      otp: this.otp.value,
    };
    this.authSvc.verifyVendorOtp(payload).subscribe((res) => {
      if (res.status) {
        const resp = { token: res.response, userName: '', userEmail: '', isMfaRegistered: true };
        this.store.setTaxpayer(resp);
        this.router.navigate(['/update-vendor'], {
          queryParams: {
            vendorId: this.params.vendorId,
            token: res.response,
          },
        });
      }
    });
  }
}
