import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@app/config/store';
import { noSpacesValidator, otp } from '@app/dto/common.dto';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-config-mfa',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './config-mfa.component.html',
  styleUrl: './config-mfa.component.css',
})
export class ConfigMfaComponent implements OnInit {
  showTimer: boolean = false;
  otpExpirationTime: number = 30;

  shouldShowSetupKey: boolean = false;
  userId: string = '';

  authType: string = 'AUTHENTICATOR';
  mfaSecretKey?: string = '';
  qrCode: string = '';
  redirectURL: string = '';

  @Input({ required: true }) isMfaRegistered: boolean = false;
  @Input() isFromUpdateMfa: boolean = false;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  mfaOtpValidationForm: FormGroup = new FormGroup({
    userId: new FormControl({ value: '', disabled: true }, []),
    otp: new FormControl('', [noSpacesValidator(), otp]),
  });

  otp: FormControl = new FormControl('', [noSpacesValidator(), otp]);

  constructor(
    protected store: Store,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}
  ngOnInit() {
    this.userId = this.store.taxpayer()?.email || '';
    this.mfaOtpValidationForm.controls['userId'].patchValue(this.userId);

    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.has('redirectUrl') && params.has('isMfaRegistered')) {
        this.isMfaRegistered = params.get('isMfaRegistered') === 'true';
        this.redirectURL = params.get('redirectUrl') || '';
      }
    });

    if (!this.isMfaRegistered && this.userId) {
      this.getMfaRegistrationDetails();
    }
  }

  getMfaRegistrationDetails() {
    this.authService.registerForMfa(this.userId).subscribe((res) => {
      if (res.status) {
        this.mfaSecretKey = res.response.mfaSecretKey;
        let image = new Image();
        image.src = `data:image/png;base64,${res.response.qrcode}`;
        this.qrCode = image.src;
      }
    });
  }

  verifyMfaOtp(event: Event) {
    event.preventDefault();

    const payload = this.isMfaRegistered
      ? this.mfaOtpValidationForm.getRawValue()
      : {
          userId: this.userId,
          otp: this.otp.value,
          secretKey: this.mfaSecretKey ?? null,
        };

    const params = { authType: this.authType };
    this.authService.verifyMfaOtp(payload, params).subscribe((res) => {
      if (res.status) {
        if (this.isFromUpdateMfa) {
          this.dismissModal();
          return;
        }
        if (!this.isMfaRegistered) {
          this.isMfaRegistered = true;
          this.mfaOtpValidationForm.controls.otp.reset();
          return;
        }
        if (res.status && res.response.token) {
          this.store.setAuthToken(res.response.token);
          this.store.setIsMfaRegistered(true);
          const query = this.redirectURL !== '' ? { redirectUrl: this.redirectURL } : {};
          this.router.navigate(['/redirect'], { queryParams: query });
        }
      }
    });
  }

  switchToEmailOtpVerification() {
    this.authType = 'EMAIL';
    this.authService.getEmailBasedOtpForMfa(this.userId).subscribe((res) => {
      if (res.status) this.mfaOtpValidationForm.controls.otp.reset();
    });
  }

  switchToAppAuthenticator() {
    this.authType = 'AUTHENTICATOR';
    this.mfaOtpValidationForm.controls.otp.reset();
  }

  startTimer() {
    this.showTimer = true;
    const timeInterval = setInterval(() => {
      if (this.otpExpirationTime === 0) {
        clearInterval(timeInterval);
        this.showTimer = false;
        this.otpExpirationTime = 30;
      }
      this.otpExpirationTime = this.otpExpirationTime - 1;
    }, 1000);
  }

  resendOtp() {
    this.authService.getEmailBasedOtpForMfa(this.userId).subscribe((res) => {
      if (res.status) this.startTimer();
    });
  }

  dismissModal() {
    this.closeModal.emit();
  }
}
