import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matAppsOutline,
  matBusinessOutline,
  matCheckOutline,
  matDevicesOutline,
  matLocalPoliceOutline,
  matLockOutline,
  matPowerSettingsNewOutline,
  matReceiptLongOutline,
  matSettingsOutline,
  matTransformOutline,
  matUnfoldMoreOutline,
  matCheckCircleOutline,
  matChangeCircleOutline,
  matOpenInNewOutline,
  matNewReleasesOutline,
} from '@ng-icons/material-icons/outline';

import { Store } from '@app/config/store';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from '@app/pages/auth/change-password/change-password.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthService, EnableDisableMfa } from '@app/services/auth.service';
import { TaxPayer } from '@app/types/global.type';
import { ConfigMfaComponent } from '../../pages/auth/config-mfa/config-mfa.component';
import { FSLUG } from '@app/constant/features';
import { DatePipe } from '@angular/common';
import { ProductGuideService } from '@app/services/product-guide.service';
import { config } from '@app/config/app';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { fetchMonthlyReleaseNotes, handleNotifications } from './utils';
import { matNotifications } from '@ng-icons/material-icons/baseline';

enum Apps {
  Einvois = 'taxpayer',
  Garnet = 'garnet',
  Settings = 'account',
}

interface Notifications {
  count: number;
  isUnRead: boolean;
  isPostLogin: boolean;
  hasCountDifference: boolean;
}

@Component({
  selector: 'app-header, [app-header]',
  standalone: true,
  imports: [
    RouterModule,
    NgIconComponent,
    NgbDropdownModule,
    ChangePasswordComponent,
    ReactiveFormsModule,
    ConfigMfaComponent,
    DatePipe,
  ],
  providers: [
    provideIcons({
      matBusinessOutline,
      matSettingsOutline,
      matAppsOutline,
      matUnfoldMoreOutline,
      matPowerSettingsNewOutline,
      matTransformOutline,
      matReceiptLongOutline,
      matLocalPoliceOutline,
      matDevicesOutline,
      matCheckOutline,
      matLockOutline,
      matCheckCircleOutline,
      matChangeCircleOutline,
      matNotifications,
      matOpenInNewOutline,
      matNewReleasesOutline,
    }),
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('notification') notificationRef!: TemplateRef<HTMLElement>;
  mfaValue: FormControl = new FormControl('no');

  Apps = Apps;
  activeApp: Apps = Apps.Einvois;
  orgName = 'Kevin Motors';
  FSLUG = FSLUG;

  releaseNoteDates: string[] = [];
  notifications: Notifications = {
    count: 0,
    isUnRead: false,
    isPostLogin: false,
    hasCountDifference: false,
  };
  showCountAfterPopup: boolean = false;

  accounts = [
    {
      id: '1',
      name: 'Kevin Motors',
      role: 'Admin',
    },
    {
      name: 'Metalbooks Pvt Ltd',
      id: '2',
      role: 'Manager',
    },
  ];

  ngOnInit(): void {
    this.fetchSetNotifications();
    if (this.store.taxpayer()?.isMfaEnable) {
      this.mfaValue.setValue('yes');
    }
  }

  constructor(
    protected store: Store,
    private ngbModal: NgbModal,
    private route: ActivatedRoute,
    private authService: AuthService,
    private productGuideService: ProductGuideService,
  ) {
    this.route.url.subscribe((url) => {
      this.activeApp = url[0].path as Apps;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openModal(content: any, size = 'sm') {
    this.ngbModal.open(content, {
      centered: true,
      size,
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  switchAccount(accountId: number | string) {
    if (accountId === this.store.activeAccount()?.orgId) return;

    this.ngbModal.dismissAll();
    this.store.switchAccount(accountId);
  }

  saveMfa() {
    const payload: EnableDisableMfa = {
      userEmail: this.store.taxpayer()?.email ?? '',
      mfaEnable: this.mfaValue.value === 'yes',
    };

    this.authService.enableDisableMfa(payload).subscribe((res) => {
      if (res.status) {
        const updatedTaxpayer = {
          ...this.store.taxpayer(),
          isMfaEnable: this.mfaValue.value === 'yes',
        } as TaxPayer;

        this.store.taxpayer.set(updatedTaxpayer);
        localStorage.setItem('taxpayer', JSON.stringify(updatedTaxpayer));

        this.closeModal();
      }
    });
  }

  //Notifications
  fetchSetNotifications() {
    const notifications: string | null = localStorage.getItem('notifications');
    if (notifications) {
      this.notifications = JSON.parse(notifications) as Notifications;
      if (this.notifications.isPostLogin) return;
    }

    this.productGuideService.getReleaseNotes().subscribe((res) => {
      if (res.status && res.response) {
        const dates =
          Array.isArray(res.response.releaseDates) && res.response.releaseDates.length
            ? res.response.releaseDates
            : [];
        const filteredDates = fetchMonthlyReleaseNotes(dates);
        this.notifications = handleNotifications(filteredDates);
        if (this.notifications.hasCountDifference) {
          this.showCountAfterPopup = true;
          this.openNotificationsModal();
        }
      }
    });
  }

  openNotificationsModal() {
    this.productGuideService.getReleaseNotes().subscribe((res) => {
      if (res.status && res.response) {
        const dates =
          Array.isArray(res.response.releaseDates) && res.response.releaseDates.length
            ? res.response.releaseDates
            : [];
        this.releaseNoteDates = fetchMonthlyReleaseNotes(dates);
        this.notifications.isUnRead = this.showCountAfterPopup;
        localStorage.setItem('notifications', JSON.stringify(this.notifications));
        if (this.showCountAfterPopup) this.showCountAfterPopup = false;
      }
    });
    this.ngbModal.open(this.notificationRef, {
      backdrop: 'static',
      windowClass: 'modal-sidebox',
      size: 'md',
    });
  }

  gotoReleaseNote(date: string) {
    if (!date) return;
    const formattedDate = dayjs(date).utc().format('YYYY-MM-DD');
    const env = config.env === 'prod' ? 'prod' : '';
    const url = `https://myeinvois.my/release-note-details/?type=${env}&userDate=${formattedDate}`;
    window.open(url, '_blank');
    this.closeModal();
  }
}
